import BaseTextComponent from "@/components/base/el/BaseTextComponent";

export default {
    name: "BaseH5",
    mixins: [BaseTextComponent],
    data() {
        return {
            tag: 'h5'
        }
    },
}
